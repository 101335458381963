
import { atom } from 'recoil'
import { POTableDataType, containerTableDataType } from './type'


export const archivedDetailDrawerIsOpen = atom({
    key: 'archivedDetailDrawerIsOpen',
    default: false
})

export const focusArchivedRecordState = atom<containerTableDataType>({
    key: 'focusArchivedRecordState',
    default: {} as containerTableDataType
})

export const archivedTableDataState = atom({
    key: 'archivedTableDataState',
    default: [] as containerTableDataType[]
})

export const focusArchivedDetailRecordState = atom({
    key: 'focusArchivedDetailRecordState',
    default:[] as  POTableDataType[]
})
export const archivedTableLoadingState = atom({
    key: 'archivedTableLoadingState',
    default: false
})

export const archivedSearchValuesState =atom({
    key:'archivedSearchValuesState',
    default:{}
 })