import {atom} from 'recoil'

 export const userTableDataState=atom({
   key:'userTableDataState',
   default:[] as any[]
})

export const userTableLoadingState=atom({
  key:'userTableLoadingState',
  default:false
})
