import { Col, Drawer, Row, Space, Table } from "antd"
import { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { archivedDetailDrawerIsOpen, archivedSearchValuesState, focusArchivedDetailRecordState, focusArchivedRecordState } from "./recoil.state"
import dayjs from 'dayjs';
import { archivedDetailTableColumn } from "./hooks";
import axios from "axios";
import { archivedDetailTableDataUrl } from "./api";

type DataType = {
  PO: string,
  boxes: number,
  pallets: number,
  deliveryDate: string,
  deliveryTime: string,
  customer: string,
  ChinaNote: string
  USNote: string
}

export const ArchivedDetailDrawer = () => {
  const [POs, setPOs] = useRecoilState(focusArchivedDetailRecordState)
  const [detailColumns, setDetailColumns] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(archivedDetailDrawerIsOpen)
  const focusArchivedRecord = useRecoilValue(focusArchivedRecordState)
  const archivedSearchValues = useRecoilValue(archivedSearchValuesState)

  useEffect(() => {
    if (Object.keys(focusArchivedRecord).length == 0) {
      return
    }
    setDetailColumns([])
    var POValues: any = {}
    for (let k of ['customer', 'loadedQTY', 'deliveryDate', 'deliveryTime', 'PO']) {
      if (archivedSearchValues[k]) {
        POValues[k] = archivedSearchValues[k]
      }
    }

    if (POValues.deliveryDate) {
      POValues.deliveryDate = dayjs(POValues.deliveryDate).format('YYYY-MM-DD')
    }
    if (POValues.deliveryTime) {
      POValues.deliveryTime = dayjs(POValues.deliveryTime).format('HH:mm')
    }
    setLoading(true)
    axios.get(archivedDetailTableDataUrl, {
      params: {
        containerId: focusArchivedRecord.containerId,
        POValues
      }
    }).then(
      res => {
        var POs = res.data.map((it: any) => ({ ...it, key: it._id }))
        if (Object.keys(POs).length == 0) {
          return
        }
        POs = POs.sort((a, b) => a.customer.localeCompare(b.customer))
        setPOs(POs)
        var columns = archivedDetailTableColumn(focusArchivedRecord.devannedDate)
        var customer = columns.find(it => it.dataIndex == 'customer')
        customer!.onCell = (record, rowIndex) => {
          const obj: any = { rowSpan: 0 }

          const occurcount = POs.filter((data) => data.customer == record.customer).length;
          rowIndex = rowIndex ? rowIndex : 0
      
          if (rowIndex == 0 || POs[rowIndex - 1].customer != record.customer) {
            obj.rowSpan = occurcount
          }
          return obj;
        }
        setDetailColumns(columns)
        setLoading(false)
      })

  }, [isDrawerOpen])


  const detailClose = () => setIsDrawerOpen(false)

  const totalBox = POs.reduce((acc, it: any) => {
    return acc + it.loadedQTY
  }, 0)
  const totalPallets = POs.reduce((acc, it: any) => {
    return acc + it.pallets
  }, 0)

  return <>
    <Drawer extra={
      <Space>

      </Space>
    } width={1300} title={'Detail #' + focusArchivedRecord.containerId} placement="right" onClose={detailClose} open={isDrawerOpen}>
      <Row style={{ fontWeight: '700' }}>
        <Col style={{ lineHeight: '50px' }}>{`TotalBoxes:${totalBox}`}</Col>
        <Col style={{ marginLeft: '20px', lineHeight: '50px' }}>{` TotalPallets:${totalPallets}`}</Col>
      </Row>
      <Table size={'small'} loading={loading}
        pagination={false}
        columns={detailColumns} dataSource={POs}></Table>
    </Drawer>
  </>
}
