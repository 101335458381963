
import {atom} from 'recoil'
import {POTableDataType, ContainerAction, containerTableDataType} from './type'


export const containerDetailDrawerIsOpenState = atom({
    key:'containerDetailDrawerIsOpenState',
    default:false
})

export const focusContainerRecordState = atom<containerTableDataType>({
    key:'focusRecord',
    default:{} as containerTableDataType
})

export const containerAddDrawerIsOpen = atom({
    key:'containerAddDrawerIsOpen',
    default:false
})

export const devanningDrawerIsOpenState=atom({
    key:'devanningDrawerIsOpen',
    default:false
 })

 
 export const tableDataState=atom({
    key:'tableDataState',
    default:[] as containerTableDataType[]
 })

 export const detailTableDataState=atom({
    key:'detailTableDataState',
    default:[] as POTableDataType[]
 })
 
 export const focusContainerDetailRecordState = atom({
    key:'focusContainerDetailRecordState',
    default:{} as POTableDataType
})

export const detailEditModelIsOpenState=atom({
    key:'detailEditModelIsOpenState',
    default:false
 })
export const containerEditModelIsOpenState=atom({
    key:'containerEditModelIsOpenState',
    default:false
 })
export const fillPalletsModelIsOpenState=atom({
    key:'fillPalletsModelIsOpenState',
    default:false
 })

 export const selectedPendingRecordsState=atom({
    key:'selectedPendingRecordsState',
    default:[] as containerTableDataType[]
 })
 export const palletsOfDevannedDrawerState=atom({
    key:'palletsOfDevannedDrawerState',
    default:[] 
 })

 export const pendingTableLoadingState = atom({
   key: 'pendingTableLoadingState',
   default: false
})

export const containerSearchValuesState =atom({
   key:'containerSearchValuesState',
   default:{}
})
export const containerPageActionListState =atom<ContainerAction[]>({
   key:'containerPageActionListState',
   default:[]
})