import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd"
import { ContainerTable } from "./containerTable"
import { useRecoilState } from "recoil"
import {  containerSearchValuesState } from "./recoil.state"
import { ContainerDetailDrawer } from "./containerDetailDrawer"
import { ContainerAddDrawer } from "./containerAddDrawer"
import { useEffect, useState } from "react"
import {  roleState } from "../stateManage"
import { DevanningDrawer } from "./devanningDrawer"
import { ContainerEditModel } from "./components/containerEditModel"
import { pendingItemList } from "./api"
import axios from "axios"
import './style.css'
import _ from 'lodash'
export const ContainerPage = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [shippers, setShippers] = useState([])
    const [containerIds, setContainerIds] = useState([])
    const [customers, setCustomers] = useState([])
    const [bills, setBills] = useState([])
    const [instructions, setInstructions] = useState([])
    const [shipperVias, setShipperVias] = useState([])
    const [POs, setPOs] = useState([])
    const [containerSearchValues, setContainerSearchValues] = useRecoilState(containerSearchValuesState);

    const [form] = Form.useForm();

    const searchClick = (values) => {
        values.loadedQTY = Number(values.loadedQTY)
        var v = _.omitBy(values, v=>v==undefined || v==null || v=='')
        setContainerSearchValues(v)
    }
    useEffect(() => {
        axios.get(pendingItemList).then(res => {
            setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
            setCustomers(res.data.customers.map(it => ({ label: it, value: it })))
            setBills(res.data.bills.map(it => ({ label: it, value: it })))
            setInstructions(res.data.instructions.map(it => ({ label: it, value: it })))
            setShipperVias(res.data.shipperVias.map(it => ({ label: it, value: it })))
            setPOs(res.data.POs.map(it => ({ label: it, value: it })))
            if (role == 'admin') {
                setShippers(res.data.shippers.map(it => ({ label: it, value: it })))
            }
        }).catch(e => {

        }).finally(() => {


        })
        if (Object.keys(containerSearchValues).length > 0) {
            form.setFieldsValue(containerSearchValues)
        }
    }, [])
    const resetClick = () => {
        setContainerSearchValues({})
    }

    var colSpan = 5
    return <>
        <Form
            labelCol={{ span: 10 }}
            // wrapperCol={{ span: 20 }}
            labelAlign="right"
            name="search"
            onFinish={searchClick}
            form={form}
        >
            <Row>
                <Col span={role == 'admin' ? colSpan : 0} >
                    <Form.Item name="shipper" label="Shipper">
                        <Select showSearch allowClear={true} options={shippers} />
                    </Form.Item>
                </Col>
                <Col span={colSpan} >
                    <Form.Item name="containerId" label="CNTR/AWB#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={containerIds}
                        />
                    </Form.Item>
                </Col>

                <Col span={colSpan} >
                    <Form.Item name="billNumber" label="Bill#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={bills}
                        />
                    </Form.Item>
                </Col>

                <Col span={colSpan}>
                    <Form.Item name="instruction" label="Instruction">
                        <Select
                            showSearch
                            allowClear={true}
                            options={instructions}
                        />
                    </Form.Item>
                </Col>
                <Col span={colSpan}>
                    <Form.Item name="shipperVia" label="shipperVia">
                        <Select
                            showSearch
                            allowClear={true}
                            options={shipperVias}
                        />
                    </Form.Item>
                </Col>


                <Col span={colSpan}>
                    <Form.Item name="customer" label="Customer">
                        <Select
                            showSearch
                            allowClear={true}
                            options={customers}
                            mode='multiple'
                        />
                    </Form.Item>
                </Col>
                <Col span={colSpan}>
                    <Form.Item name="PO" label="PO#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={POs}
                        />
                    </Form.Item>
                </Col>
                <Col span={colSpan}>
                    <Form.Item name="loadedQTY" label="Loaded Q’ty">
                        <Input type="number" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={colSpan}>
                    <Form.Item name="ETD" label="ETD">
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>
                </Col>


                <Col span={colSpan}>
                    <Form.Item name="ETA" label="ETA">
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>
                </Col>
                <Col span={colSpan}>
                    <Form.Item name="deliveryDate" label="Est. Del. Date" >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>
                </Col>
                <Col span={colSpan}>
                    <Form.Item name="deliveryTime" label="Est. Del. Time">
                        <TimePicker format={'HH:mm'} minuteStep={10} changeOnBlur={true} showNow={false} />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="end" >
                {/* <Col >
                    <Button type="primary" onClick={optionsClick} icon={<SettingOutlined />} size={"middle"} />
                </Col> */}
                <Col  >
                    <Form.Item style={{ marginRight: '20px' }} >
                        <Button type="primary" htmlType="submit">Search</Button >
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item   >
                        <Button onClick={resetClick} type="primary" htmlType="reset">Reset</Button >
                    </Form.Item>
                </Col>

            </Row>
        </Form>

        <ContainerTable />
        <ContainerDetailDrawer />
        <ContainerAddDrawer />
        <DevanningDrawer />
        <ContainerEditModel />
    </>
}