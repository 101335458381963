import { useRecoilState } from "recoil";
import { dispatchManyEditModelIsOpenState, inHousedActionListState, selectedDevannedRecordsState } from "./recoil.state";
import { DrawerForm, ProFormDatePicker, ProFormDigit, ProFormGroup, ProFormList, ProFormSelect, ProFormText, ProFormTimePicker } from "@ant-design/pro-form";
import { useEffect, useState } from "react";
import { doDispatchedManyUrl } from "./api";
import axios from "axios";
import { Col, Form, Row, message } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { InHousedAction } from "./type";
import { deliveryMethodOptions } from "./const";



const title = 'Delivery'
export const DoSelectedDeliveryItems = () => {
    const [selectedDevannedRecords, setSelectedDevannedRecords] = useRecoilState(selectedDevannedRecordsState);
    const [formLoading, setFormLoading] = useState(false)
    const [dispatchManyEditModelIsOpen, setDispatchManyEditModelIsOpen] = useRecoilState(dispatchManyEditModelIsOpenState)
    const [inHousedActionList, setInHousedActionList] = useRecoilState(inHousedActionListState)

    const [form] = Form.useForm();
    const [selected, setSelected] = useState([])
    useEffect(() => {
        if (selectedDevannedRecords.length > 0) {
            let recds = JSON.parse(JSON.stringify(selectedDevannedRecords))

            form.setFieldValue('records', recds)
        } else {
            form.setFieldValue('records', [])
        }

    }, [dispatchManyEditModelIsOpen])
    const openChange = (t) => {
        setDispatchManyEditModelIsOpen(t)
    }
    const bulkDeliveryClick = async (values) => {
        setFormLoading(true)

        let records = values.records
        if (values.deliveryDate) {
            records.forEach(it => {
                it.deliveryDate = values.deliveryDate
            })
        }
        if (values.deliveryMethod) {
            records.forEach(it => {
                it.deliveryMethod = values.deliveryMethod
            })
        }

        axios.put(doDispatchedManyUrl, {
            records
        }).then(res => {
            message.success('submit success')
            //更新表格
            var inHousedActions: InHousedAction[] = JSON.parse(JSON.stringify(inHousedActionList))
            inHousedActions.push('bulkDelivery')
            setInHousedActionList(inHousedActions)
        }).catch(e => {

        }).finally(() => {
            setFormLoading(false)
        })
        return true
    }
    return <>{dispatchManyEditModelIsOpen &&
        <DrawerForm
            loading={formLoading}
            width={1300}
            // onFieldsChange={()=>{}}
            onOpenChange={openChange}
            title={title}
            form={form}
            open={dispatchManyEditModelIsOpen}
            onFinish={bulkDeliveryClick}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
        >

            <Row>
                <Col >
                    <ProFormDatePicker initialValue={dayjs()} rules={[{ required: true }]} fieldProps={{ format: 'YYYY-MM-DD' }} name="deliveryDate" label="DeliveryDate" />
                </Col>
                <Col span={4}>
                    <ProFormSelect rules={[{ required: true }]}
                        label='deliveryMethod' name='deliveryMethod' options={deliveryMethodOptions.map(it => ({ label: it, value: it }))} />
                </Col>
            </Row>

            <ProFormList
                name="records"
                min={1}
                copyIconProps={false}
                creatorButtonProps={false}
                // creatorRecord={{ name: '', items: [{ name: '' }] }}
                initialValue={selected}
                deleteIconProps={{
                    Icon: CloseCircleOutlined,
                    tooltipText: 'Delete',
                }}
            >

                <ProFormGroup key="group">
                    <Row>
                        <Col span={4}>
                            <ProFormText
                                width="md"
                                name="billNumber"
                                label="Bill#"
                                placeholder=""
                                disabled
                            // initialValue={it.billNumber}
                            />
                        </Col>
                        <Col span={4}>
                            <ProFormText
                                width="md"
                                name="customer"
                                label="Customer"
                                placeholder=""
                                disabled
                            // initialValue={it.vendor}
                            />
                        </Col>
                        <Col span={3}>
                            <ProFormText

                                width="md"
                                name="PO"
                                label="PO#"
                                placeholder=""
                            // initialValue={it.PO}
                            />
                        </Col>
                        <Col span={3}>
                            <ProFormDigit

                                width="md"
                                name="loadedQTY"
                                label="LoadedQTY"
                                placeholder=""
                            // initialValue={it.loadedQTY}
                            />
                        </Col>
                        <Col span={2}>
                            <ProFormDigit
                                width="md"
                                name="pallets"
                                label="Pallets"
                                placeholder=""
                            // initialValue={it.pallets}
                            />
                        </Col>
                        <Col >
                            <ProFormDatePicker disabled name="deliveryDate" label="Est. Del. Date" />
                        </Col>
                        <Col >
                            <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                        </Col>
                    </Row>

                </ProFormGroup>
            </ProFormList>

        </DrawerForm>}
    </>
}