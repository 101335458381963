import { useRecoilState, useRecoilValue } from "recoil"
import { isLoginState, roleState } from "./stateManage"
import { LoginPage } from "./login/login"
import { Home } from "./home"
import axios from "axios";
import { apiHost } from "./apiInfo";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";



export const App = () => {
  const isLogin = useRecoilValue(isLoginState)
  const [role,setRole] = useRecoilState(roleState)
  useEffect(()=>{
    axios.defaults.baseURL = apiHost
    axios.defaults.withCredentials = true;
    axios.defaults.timeout = 30*1000;
    setRole(localStorage.getItem('role'))
  },[])
  return <>
    <HashRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/page/home" element={<Home />} />
      </Routes>
    </HashRouter>
  </>
}