import {  useEffect, useState } from "react";
import { instructionOptionState, roleState, shipViaState } from '../stateManage'
import { useRecoilState, useRecoilValue } from "recoil";
import {  Col, Divider, Form, Input, Row, Select, message } from "antd";
import { CloseCircleOutlined, CopyOutlined } from '@ant-design/icons';

import {
    DrawerForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormList,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker,
} from '@ant-design/pro-form';
import axios from "axios";
import { containerAddDrawerIsOpen, containerPageActionListState } from "./recoil.state";
import { addContainerUrl } from "./api";
import { ContainerAction } from "./type";

export const ContainerAddDrawer = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [isAddDrawerOpen, setIsAddDrawerOpen] = useRecoilState(containerAddDrawerIsOpen);
    const instructionOptions = useRecoilValue(instructionOptionState)
    const shipViaOptions = useRecoilValue(shipViaState)
    const [submitLoading, setSubmitLoading] = useState(false);
    const [containerPageActionList, setContainerPageActionList] = useRecoilState(containerPageActionListState);
    
    const title = 'Add Shipment'

    const onClose = () => {
        setIsAddDrawerOpen(false);
    };
    const addShipmentSave = async (values: any) => {
        // setSubmitLoading(true)

        if (role == 'admin') {
            values.shipper = values.shipper.toUpperCase()
        } else {

        }
        if (values.operator) {
            values.operator = values.operator.toUpperCase()
        }
        if (values.containerId) {
            values.containerId = values.containerId.toUpperCase()
        }
        if (values.billNumber) {
            values.billNumber = values.billNumber.toUpperCase()
        }
        if (values.billNumber) {
            values.billNumber = values.billNumber.toUpperCase()
        }
        values.POs.forEach(it => {
            it.customer = it.customer.toUpperCase()
            it.PO = it.PO.toUpperCase()
        })
        axios.post(addContainerUrl, { containerInfo: values }).then((res) => {
            message.success('submit success');

            // 更新表格
            var containerPageActions: ContainerAction[] = JSON.parse(JSON.stringify(containerPageActionList));
            containerPageActions.push('addShipment')
            setContainerPageActionList(containerPageActions)
        }).catch((e) => {

        }).finally(() => {
            setSubmitLoading(false)

        })
        return true
    }

    useEffect(() => {

    }, [])

    return <DrawerForm
        loading={submitLoading}
        width={1700}
        onOpenChange={setIsAddDrawerOpen}
        title={title}
        open={isAddDrawerOpen}
        onFinish={addShipmentSave}
        submitter={{
            searchConfig: {
                submitText: 'Confirm',
                resetText: 'Cancel',
            },
        }}
    >

        <Row>
            <Col span={role == 'admin' ? 3 : 0} >
                <Form.Item name="shipper" label="Shipper" rules={[{ required:role == 'admin' ?true:false }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={3} offset={0}> <ProFormText
                width="md"
                name="operator"
                label="operator"
                placeholder={''}
                rules={[{ required: true }]}
            /></Col>
            <Col span={2} >
                <Form.Item name="shipperVia" label="Ship Via:">
                    <Select
                        options={shipViaOptions}
                    />
                </Form.Item>
            </Col>
            <Col span={3} offset={0}>
                <Form.Item name="instruction" label="Instruction">
                    <Select
                        options={instructionOptions}
                    />
                </Form.Item>
            </Col>
            <Col span={3}>
                <Form.Item name="containerId" label="CNTR/AWB#" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Col>

            <Col span={3} >
                <ProFormText
                    width="md"
                    name="billNumber"
                    label="Bill#"
                    placeholder={''}
                    rules={[{ required: true }]}
                />
            </Col>

            <Col ><ProFormDatePicker label="ETD" name='ETD' /></Col>
            <Col ><ProFormDatePicker label="ETA" name='ETA' /></Col>
        </Row>
        <Row>
            <Col><ProFormTextArea label="CNTR Notes" name="chinaNotes" width='lg' /></Col>
        </Row>

        <ProFormList
            name="POs"
            creatorButtonProps={{
                creatorButtonText: 'Add Item',
            }}
            min={1}
            copyIconProps={{ Icon: CopyOutlined, tooltipText: 'Copy' }}
            deleteIconProps={{
                Icon: CloseCircleOutlined,
                tooltipText: 'Delete',
            }}
            initialValue={[
                {},
            ]}
        >
            <Row>
                <Col span={3}><ProFormText
                    style={{ padding: 0 }}
                    width="md"
                    name="customer"
                    label="Customer"
                    placeholder={''}
                    rules={[{ required: true }]}
                /></Col>
                <Col span={3} offset={0}><ProFormText
                    style={{ padding: 0 }}
                    width="md"
                    name="PO"
                    label="bill/po#"
                    placeholder={''}
                    rules={[{ required: true }]}
                /></Col>
                <Col span={2}><ProFormDigit
                    style={{ padding: 0 }}
                    width="md"
                    name="loadedQTY"
                    label="loaded QTY"
                    placeholder={''}
                    rules={[{ required: true }]}
                /></Col>
                <Col span={2}><ProFormDigit
                    style={{ padding: 0 }}
                    width="md"
                    name="totalQTY"
                    label="total QTY"
                    placeholder={''}
                    rules={[{ required: true }]}
                /></Col>
                <Col>
                    <ProFormDatePicker name="deliveryDate" label="Est. Del. Date" />
                </Col>
                <Col >
                    <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="Est. Del. Time" />
                </Col>
                <Col><ProFormTextArea label="ChinaNote" name="PO_chinaNotes" width='sm' /></Col>
                <Col><ProFormTextArea label="USNote" name="PO_US_Notes" width='sm' /></Col>
            </Row>
        </ProFormList>
        <Divider />
        {/* <Row justify={'end'}>
            <Col><span style={{ fontWeight: 'bold' }}>{`TotalBoxes:${totalBox}`}</span></Col>
        </Row> */}
    </DrawerForm>
}

