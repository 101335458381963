import {  roleState, userInfoState } from '../stateManage';
import {
    LockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    LoginForm,
    ProConfigProvider,
    ProFormCheckbox,
    ProFormText,
    setAlpha,
} from '@ant-design/pro-components';
import { message, theme } from 'antd';
import axios from 'axios';
import type { CSSProperties } from 'react';
import { useRecoilState } from 'recoil';
import { authUrl } from './api';
import { useNavigate } from "react-router-dom";


export const LoginPage = () => {
    const [role, setRole] = useRecoilState(roleState)
    const { token } = theme.useToken();
    const [userInfo, setUserInfo] = useRecoilState(userInfoState)
    var navigate = useNavigate();
    const iconStyles: CSSProperties = {
        marginInlineStart: '16px',
        color: setAlpha(token.colorTextBase, 0.2),
        fontSize: '24px',
        verticalAlign: 'middle',
        cursor: 'pointer',
    };
    const onFinish = (formData): any => {
        axios.post(authUrl, {
            userInfo: formData
        }).then(res => {
            setRole(res.data.role)
            window.localStorage.setItem('role',res.data.role)
            window.localStorage.setItem('userName',formData.username)
            window.localStorage.setItem('MenuKey', 'Schedules')
            
            navigate('/page/home', { replace: true })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }).catch(e => {
            message.error(e.message)
        })

    }
    const onFinishFailed = (formData) => { }
    const validateMessages = {
        required: "'${name}' 是必选字段",
        // ...
    };
    return (<>
        <ProConfigProvider hashed={false}>
            <div style={{ marginTop: '150px' }}></div>
            <div style={{ backgroundColor: token.colorBgContainer }}>
                <LoginForm
                    validateMessages={validateMessages}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    title="EDGY Shipment Management"
                    subTitle=" "
                >
                    <>
                        <ProFormText
                            name="username"
                            fieldProps={{
                                size: 'large',
                                prefix: <UserOutlined className={'prefixIcon'} />,
                            }}
                            placeholder={'username'}
                            rules={[
                                {
                                    required: true,
                                    message: 'try again!',
                                },
                            ]}
                        />
                        <ProFormText.Password
                            name="password"
                            fieldProps={{
                                size: 'large',
                                prefix: <LockOutlined className={'prefixIcon'} />,
                            }}
                            placeholder={'password'}
                            rules={[
                                {
                                    required: true,
                                    message: 'try again',
                                },
                            ]}
                        />
                    </>
                    <div
                        style={{
                            marginBlockEnd: 24,
                        }}
                    >
                        <ProFormCheckbox initialValue={true} noStyle name="autoLogin">
                            Remember me
                        </ProFormCheckbox>
                        <a
                            style={{
                                float: 'right',
                            }}
                        >
                            forget
                        </a>
                    </div>
                </LoginForm>
            </div>
        </ProConfigProvider>

    </>
    );
};