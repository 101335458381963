import { atom } from 'recoil'
import { DevanningType, InHousedAction } from './type'

export const focusDevannedRecordState = atom({
   key: 'focusDevannedRecordState',
   default: {} as DevanningType
})


export const dispatchEditModelIsOpenState = atom({
   key: 'dispatchEditModelIsOpenState',
   default: false
})
export const editItemsModelIsOpenState = atom({
   key: 'inHousedEditItemsModelIsOpenState',
   default: false
})
export const editModelIsOpenState = atom({
   key: 'editModelIsOpenState',
   default: false
})
export const dispatchManyEditModelIsOpenState = atom({
   key: 'dispatchManyEditModelIsOpenState',
   default: false
})
export const timeEditModelIsOpenState = atom({
   key: 'timeEditModelIsOpenState',
   default: false
})

export const devannedTableDataState = atom({
   key: 'devannedTableDataState',
   default: [] as any[]
})

export const selectedDevannedRecordsState = atom({
   key: 'selectedDevannedRecordState',
   default: [] as DevanningType[]
})


export const devannedTableLoadingState=atom({
   key:'devannedTableLoadingState',
   default:false
 })
 


 export const inHousedSearchValuesState =atom({
   key:'inHousedSearchValuesState',
   default:{}
})

export const inHousedActionListState =atom<InHousedAction[]>({
   key:'inHousedActionListState',
   default:[]
})

export const locationsState = atom({
   key: 'locationsState',
   default: [] as any[]
})

export const bulkTransferModelIsOpenState = atom({
   key: 'bulkTransferModelIsOpenState',
   default: false
})