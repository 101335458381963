import { Button, Col, Form, Input, Row, Select,  message } from "antd"
import axios from "axios"
import { useRecoilState } from "recoil"
import {  userTableLoadingState } from "./recoil.state"
import { UserPageTable } from "./userPageTable"
import { addUserUrl } from "./api"

type ShowColumn = {
    shipper: string,
    container: string,
    ETD: string,
    ETA: string,
    type: string
    invoiceId: string,
    key: string
}
type DataType = {
    key: string
}
export const UserPage = () => {
    const [userTableLoading, setUserTableLoading] = useRecoilState(userTableLoadingState)
    const [form] = Form.useForm();

    const onFinish = (values) => {
        setUserTableLoading(true)
        axios.post(addUserUrl, { values }).then(res => {
            message.success('add success')
           
        }).catch(e => {

        }).finally(() => {
            setUserTableLoading(false)
        })
    }

    return <>
        <Form
            name="search"
            onFinish={onFinish}
            form={form}
            style={{ marginTop: '20px' }}>
            <Row>
                <Col span={4} offset={0}>
                    <Form.Item name="email" label="User Name">
                        <Input allowClear={true} />
                    </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                    <Form.Item name="password" label="Password">
                        <Input allowClear={true} />
                    </Form.Item>
                </Col>


                <Col span={4} offset={1}>
                    <Form.Item name="shipper" label="Shipper">
                        <Input allowClear={true} />
                    </Form.Item>
                </Col>


                <Col span={4} offset={1}>
                    <Form.Item name="role" label="Role">
                        <Select
                            showSearch
                            allowClear={true}
                            options={[  { value: 'user', label: 'user' },
                            { value: 'admin', label: 'admin' },]}
                        />
                    </Form.Item>
                </Col>

            </Row>

            <Row justify="end" >
                {/* <Col >
                    <Button type="primary" onClick={optionsClick} icon={<SettingOutlined />} size={"middle"} />
                </Col> */}
                <Col  >
                    <Form.Item  >
                        <Button type="primary" htmlType="submit">Add</Button >
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <UserPageTable />

    </>
}


