
export const containerTableUrl = '/container/tabledata'
export const addContainerUrl = '/container/add'
export const saveContainerEditUrl = '/container/editsave'
export const saveContainerDetailEditUrl = '/container/detail/editsave'
export const searchContainerUrl = '/container/search/tabledata'
export const insertDevannedUrl = '/devanned/dodevanning'
export const pendingItemList = '/container/itemList'
export const uploadExcel ='/container/uploadexcel'
export const deleteContainerUrl = '/container/deletebyids'
export const devannedItemList = '/devanned/itemList'
