import React, { useEffect, useState } from 'react';
import { Breadcrumb, Layout, Menu, Button, theme, Table, Space, message, Dropdown } from 'antd';
import { PageShow } from './pageShow';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginState, menu, roleState, userInfoState } from './stateManage'
import { getShippersUrl } from './apiInfo'
import { LoginPage } from './login/login';
import { useNavigate } from 'react-router-dom';
import { ProLayout } from '@ant-design/pro-layout';
import {
  UserOutlined,
  SmileFilled,
  LogoutOutlined
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;

export const Home = () => {
  const [role, setRole] = useRecoilState(roleState)
  const navigate = useNavigate()
  const [selectKey, setSelectKey] = useState('')
  const [isLogin, setIsLogin] = useRecoilState(isLoginState)
  const userInfo = useRecoilValue(userInfoState)
  if (role == 'admin' && !menu.some(it => it == 'User')) {
    menu.push('User')
  }
  function clickItem(e: any) {
    window.localStorage.setItem('MenuKey', e.key)
    setSelectKey(e.key)
  }
  useEffect(() => {
    let key = window.localStorage.getItem('MenuKey')
    if (key) {
      setSelectKey(key)
    } else {
      setSelectKey('Pending')
    }

    axios.interceptors.response.use(function (response) {

      return response;
    }, function (error) {
      var statusCode = error.response.data.statusCode
      if (statusCode == 401) {
        navigate('/')
      } else {
        message.error(error.message)
      }
      return Promise.reject(error);
    });


  }, [])

  return (
    <ProLayout className="layout" layout='top'
      title={''} logo={null} token={{
        header: {
          colorTextMenu: '#dfdfdf',
          colorBgHeader: '#001529',
        }

        // colorBgHeader: '#292f33',
      }}
      avatarProps={{
        icon: <UserOutlined />,
        size: 'small',
        title: <div style={{ color: '#dfdfdf' }}>{localStorage.getItem('userName')}</div>,
        render: (props, dom) => {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    label: 'LogOut',
                    onClick: () => {
                      window.document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                      navigate('/')
                    }
                  },
                ],
              }}
            >
              {dom}
            </Dropdown>
          );
        }
      }}
      route={{
        path: '/',
        routes: [
          {
            path: '/welcome',
            name: '欢迎',
            icon: <SmileFilled />,
            component: './Welcome',
          }]
      }}
      menuProps={{
        onClick: clickItem,
        style: { width: '100%' },
        theme: "dark",
        mode: "horizontal",
        selectedKeys: [selectKey],
        items: menu.map((it, index) => {
          const key = it;
          return {
            key,
            label: it,
          };
        })
      }}
    >

      <Content >
        <div className="site-layout-content" ><PageShow tkey={selectKey} /> </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}></Footer>
    </ProLayout>
  );
};