import React, {  useEffect} from 'react';
import {  Table,  } from 'antd';
import {  searchArchivedUrl } from './api';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { archivedTableDataState, archivedTableLoadingState, archivedSearchValuesState } from './recoil.state';
import { roleState } from '../stateManage';
import { useArchivedTableColumn } from './hooks';
import dayjs from 'dayjs';

export const ArchivedTable: React.FC = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [tableData, setTableData] = useRecoilState(archivedTableDataState)
    const [archivedTableLoading, setArchivedTableLoading] = useRecoilState(archivedTableLoadingState)
    const archivedSearchValues = useRecoilValue(archivedSearchValuesState)
    const columns = useArchivedTableColumn()

    useEffect(() => {
        setArchivedTableLoading(true)
        var values = JSON.parse(JSON.stringify(archivedSearchValues))
        if (values.devannedDate) {
            values.devannedDate = dayjs(values.devannedDate).format('YYYY-MM-DD')
        }
        if (values.ETA) {
            values.ETA = dayjs(values.ETA).format('YYYY-MM-DD')
        }
        if (values.ETD) {
            values.ETD = dayjs(values.ETD).format('YYYY-MM-DD')
        }
        if (values.deliveryDate) {
            values.deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD')
        }
        if (values.deliveryTime) {
            values.deliveryTime = dayjs(values.deliveryTime).format('HH:mm')
        }
        if(values.loadedQTY){
            values.loadedQTY =Number(values.loadedQTY)
        }
        axios.post(searchArchivedUrl, { values,pageNum:1,pageSize:100 }).then(res => {
            var dataRes = res.data.map((it: any) => ({ ...it, key: it._id }))
            // 重复的containerId合并shipmentQTY
            function isDuplicate(arr, obj) {
                return arr.some(item => item.containerId === obj.containerId);
            }
            dataRes = dataRes.reduce((acc, obj) => {
                if (!isDuplicate(acc, obj)) {
                    acc.push(obj);
                }else{
                    var containerObject = acc.find(it=>it.containerId == obj.containerId)
                    containerObject.shipmentQTY += obj.shipmentQTY
                }
                return acc;
            }, []);
            setTableData(dataRes)
        }).catch(e => {

        }).finally(() => {
            setArchivedTableLoading(false)
        })

    }, [archivedSearchValues])
    const tableChange =(pagination)=>{
        setArchivedTableLoading(true)
        var values = JSON.parse(JSON.stringify(archivedSearchValues))
        axios.post(searchArchivedUrl, { values,pageNum:pagination.current,pageSize:100 }).then(res => {
            const dataRes = res.data.map((it: any) => ({ ...it, key: it._id }))
            setTableData(dataRes)
        }).catch(e => {

        }).finally(() => {
            setArchivedTableLoading(false)
        })
    }
    
    return <>
        <Table size={'small'}
        onChange={tableChange}
            pagination={{ total: tableData.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, pageSize: 100, showSizeChanger: false, }}
            loading={archivedTableLoading}
            sticky={true}
            rowClassName="editable-row"
            dataSource={tableData} scroll={{ x: 1700, y: 1000 }} columns={columns}></Table>
    </>
};