import {atom} from 'recoil'
import { DeliveredAction } from './type'

 export const dispatchedTableDataState=atom({
   key:'dispatchedTableDataState',
   default:[] as any[]
})

export const deliveredTableLoadingState=atom({
  key:'deliveredTableLoadingState',
  default:false
})

export const deliverySearchValuesState =atom({
  key:'deliverySearchValuesState',
  default:{}
})

export const deliveryPageActionListState =atom<DeliveredAction[]>({
  key:'deliveryPageActionListState',
  default:[]
})
export const summaryDrawerIsOpenState = atom({
  key:'deliverySummaryDrawerIsOpenState',
  default:false
})
export const editItemsModelIsOpenState = atom({
  key:'deliveryEditItemsModelIsOpenState',
  default:false
})

export const selectedDeliveredRecordsState = atom({
  key: 'selectedDeliveredRecordsState',
  default: [] as any[]
})
