
import {atom} from 'recoil'
import { ScheduleAction } from './type'

export const dashboardSearchOptionsIsOpenState = atom({
    key:'dashboardSearchOptionsIsOpenState',
    default:false
})
export const summaryDrawerIsOpenState = atom({
    key:'scheduleSummaryDrawerIsOpenState',
    default:false
})
export const bulkEditIsOpenState = atom({
    key:'bulkEditIsOpenState',
    default:false
})

 export const dashboardTableDataState=atom({
    key:'dashboardTableDataState',
    default:[]
 })
 export const scheduleTableLoadingState=atom({
    key:'scheduleTableLoadingState',
    default:false
 })
 export const selectedScheduleRecordsState=atom({
    key:'selectedScheduleRecordsState',
    default:[]
 })

 export const scheduleSearchValuesState =atom({
    key:'scheduleSearchValuesState',
    default:{}
 })

 export const scheduleActionListState =atom<ScheduleAction[]>({
    key:'scheduleActionListState',
    default:[]
 })
 