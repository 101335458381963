import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Popconfirm, Popover, Row, Table, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ScheduleTwoTone } from '@ant-design/icons';
import printJS from 'print-js'
import { bulkEditIsOpenState, dashboardSearchOptionsIsOpenState, dashboardTableDataState, scheduleActionListState, scheduleSearchValuesState, scheduleTableLoadingState, selectedScheduleRecordsState, summaryDrawerIsOpenState } from './recoil.state';
import { roleState } from '../stateManage';
import { searchDashboardUrl } from './api';
import dayjs from 'dayjs';

export const ContainerTable: React.FC = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [scheduleTableLoading, setScheduleTableLoading] = useRecoilState(scheduleTableLoadingState)
    const [tableData, setTableData] = useRecoilState(dashboardTableDataState)
    const [dashboardSearchOptionsIsOpen, setDashboardSearchOptionsIsOpen] = useRecoilState(dashboardSearchOptionsIsOpenState)
    const [summaryDrawerIsOpen, setSummaryDrawerIsOpen] = useRecoilState(summaryDrawerIsOpenState)
    const [bulkEditIsOpen, setBulkEditIsOpen] = useRecoilState(bulkEditIsOpenState)
    const [column, setColumn] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedScheduleRecords, setSelectedScheduleRecords] = useRecoilState(selectedScheduleRecordsState);
    const scheduleSearchValues = useRecoilValue(scheduleSearchValuesState)
    const [scheduleActionList, setScheduleActionList] = useRecoilState(scheduleActionListState)


    const fillTableData = (dataRes) => {
        dataRes = dataRes.map((it: any) => ({ ...it, key: it.containerId }))
        setTableData(dataRes)
    }

    useEffect(() => {
        setSelectedRowKeys([])
        setScheduleTableLoading(true)
        var searchVals = JSON.parse(JSON.stringify(scheduleSearchValues))
        if (searchVals.customer && searchVals.customer.length == 0) {
            delete searchVals.customer
        }


        var values = JSON.parse(JSON.stringify(searchVals))
        if (values.ETA) {
            values.ETA = dayjs(values.ETA).format('YYYY-MM-DD')
        }
        if (values.ETD) {
            values.ETD = dayjs(values.ETD).format('YYYY-MM-DD')
        }
        if (values.deliveryDate) {
            values.deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD')
        }
        if (values.deliveryTime) {
            values.deliveryTime = dayjs(values.deliveryTime).format('HH:mm')
        }
        if (values.loadedQTY == "") {
            delete values.loadedQTY
        }
        axios.post(searchDashboardUrl, { values }).then(res => {
            const dataRes = res.data.map((it: any) => ({ ...it, key: it._id }))
            setTableData(dataRes)
        }).catch(e => {

        }).finally(() => {
            setScheduleTableLoading(false)
        })

        var columns = getColumns(role, tableData.length > 0 ? tableData : [])

        setColumn(columns)
    }, [scheduleSearchValues, scheduleActionList])

    const printClick = () => {
        const jsonString = JSON.stringify(tableData);
        var data = JSON.parse(jsonString);
        data.forEach((it) => {
            if (!it.pallets) {
                it.pallets = '-'
            }
            if (!it.deliveryDate) {
                it.deliveryDate = '-'
            }
            if (!it.deliveryTime) {
                it.deliveryTime = '-'
            }
        })

        const prop = [
            { field: 'containerId', displayName: 'CNTR/AWB#' },
            { field: 'billNumber', displayName: 'Bill#' },
            { field: 'customer', displayName: 'Customer' },
            { field: 'PO', displayName: 'PO' },
            { field: 'loadedQTY', displayName: 'Loaded Q’ty' },
            { field: 'totalQTY', displayName: 'Total Q’ty' },
            { field: 'pallets', displayName: 'Pallets' },
            { field: 'deliveryDate', displayName: 'Est. Del. Date' },
            { field: 'deliveryTime', displayName: 'Est. Del. Time' },
            { field: 'status', displayName: 'Status' },
            // { field: 'PO_chinaNotes', displayName: 'PO chinaNotes' },
        ]
        if (role == 'admin') {
            prop.unshift({ field: 'shipper', displayName: 'Shipper' })
        }
        const gridStyle = 'border: 1px solid lightgray; margin-bottom: -1px;'
            + 'text-align:center;'
            + 'font-size:18px;'
        printJS({
            printable: data, type: 'json',
            properties: prop,
            gridStyle
        })
    }
    const summaryClick = () => {
        setSummaryDrawerIsOpen(true)
    }
    const bulkEditClick = () => {
        setBulkEditIsOpen(true)
    }
    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedScheduleRecords(selectedRows)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return <>
        <Row justify='end' style={{ margin: '10px', marginRight: 0 }}>
            <Col >
                <Button type="primary" onClick={printClick} disabled={tableData.length == 0 || scheduleTableLoading} >
                    Print
                </Button>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
                <Button type="primary" onClick={summaryClick} disabled={!scheduleSearchValues['deliveryDate'] || tableData.length == 0 || scheduleTableLoading} >
                    Summary By Date
                </Button>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
                <Button type="primary" onClick={bulkEditClick} disabled={selectedRowKeys.length == 0 || scheduleTableLoading} >
                    Bulk Edit
                </Button>
            </Col>
        </Row>
        <Table size={'small'}
            sticky={true}
            rowSelection={rowSelection}
            id={'dashboardTable'}
            pagination={{ total: tableData.length, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, pageSize: 100, showSizeChanger: false, }}
            loading={scheduleTableLoading}

            rowClassName="editable-row"
            dataSource={tableData} scroll={{ x: 1400, y: 1000 }} columns={column}></Table>
    </>
};

function getColumns(role, tableData) {
    const columnDefaultWidth = 150
    var columns = [['Bill#', 'billNumber'], ['CNTR/AWB#', 'containerId'], ['Customer', 'customer'],
    ['PO#', 'PO'], ['Loaded Q’ty', 'loadedQTY'], ['Pallets', 'pallets'],['Est. Del. Date', 'deliveryDate'], ['Est. Del. Time', 'deliveryTime'], ['Status', 'status']]
    if (role == 'admin') {
        columns.unshift(['Shipper', 'shipper'])
        columns.splice(9, 0, ['Location', 'location'])
    }
    let mergedColumns = columns.map((it, i) => {
        const width = it[2] ? it[2] : columnDefaultWidth

        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            align: 'center',
            width: width,
            // sorter: true,
            onCell: (record, rowIndex) => {
                var rowSpan = 1

                if ((String(it[0]).includes('Load') || String(it[0]).includes('Pallet')) && tableData.length > 0) {
                    rowSpan = record.rowSpan
                }

                return {
                    rowSpan: rowSpan,
                    record,
                    title: it[0],
                    dataindex: it[1],
                }
            },
            render: (text, record, index) => {
                if (text !== 0 && text == null) {
                    return '-'
                }
                if (String(it[0]).includes('Note')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                if (String(it[0]).includes('Time')) {
                    return text.slice(0, 5)
                }
                if (String(it[0]).includes('Load') && record.loadedQTY != record.totalQTY) {
                    return <strong style={{ color: '#ff0000', }}>{`${record.loadedQTY}/${record.totalQTY}`}</strong>
                }
                return text
            }
        }
    }) as any

    // 日期排序Est. Del. Date
    var deliveryDateCol = mergedColumns.find(it => it.dataIndex == 'deliveryDate')
    deliveryDateCol.sorter = (a, b) => {
        return new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime()
    }
    return mergedColumns
}