import {
    ModalForm,
    ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTimePicker,
} from '@ant-design/pro-form';
import {  Col, Form, Row, message } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from 'axios';
import { doDispatchedUrl } from './api';
import { useEffect } from 'react';
import { dispatchEditModelIsOpenState, focusDevannedRecordState, inHousedActionListState } from './recoil.state';
import { InHousedAction } from './type';

type DeliveredMethod = 'EDGY Local Del.' | 'Other Local Del.' | 'Self Pick-up' | '3rd Party LTL Del.'

export const DispatchEditModel = () => {
    const focusDevannedRecord = useRecoilValue(focusDevannedRecordState)
    const [dispatchEditModelIsOpen, setDispatchEditModelIsOpen] = useRecoilState(dispatchEditModelIsOpenState)
    const [inHousedActionList, setInHousedActionList] = useRecoilState(inHousedActionListState)
    const [form] = Form.useForm();

    const deliveryMethodOptions = ['EDGY Local Del.', 'Other Local Del.', 'Self Pick-up', '3rd Party LTL Del.','Courier Del.']

    const deliveryClick = (values) => {
        axios.put(doDispatchedUrl, {
            id: focusDevannedRecord._id, values
        }).then(res => {
            message.success('submit success')

            //更新表格
            var inHousedActions: InHousedAction[] = JSON.parse(JSON.stringify(inHousedActionList))
            inHousedActions.push('delivery')
            setInHousedActionList(inHousedActions)
        }).catch(e => {

        })
    }
    useEffect(() => {
        form.setFieldsValue({ ...focusDevannedRecord })
    }, [dispatchEditModelIsOpen])
    const span = 7
    return (
        <ModalForm
            open={dispatchEditModelIsOpen}
            title={`Delivery# ${focusDevannedRecord.containerId}`}
            form={form}
            autoFocusFirstInput
            onOpenChange={(p) => setDispatchEditModelIsOpen(p)}
            submitTimeout={2000}
            onFinish={async (values) => {
                deliveryClick(values)
                return true;
            }}
        >
            <ProForm.Group>
                <Row>
                    <Col span={span}>
                        <ProFormText
                            width="md"
                            name="billNumber"
                            label="Bill#"
                            placeholder=""
                            disabled
                            initialValue={focusDevannedRecord.billNumber}
                        />
                    </Col>
                    <Col span={span}>
                        <ProFormText
                            width="md"
                            name="customer"
                            label="Customer"
                            placeholder=""
                            disabled
                            initialValue={focusDevannedRecord.customer}
                        />
                    </Col>
                    <Col span={span}>
                        <ProFormText
                            width="md"
                            name="PO"
                            label="PO#"
                            placeholder=""
                            initialValue={focusDevannedRecord.PO}
                            disabled
                        />
                    </Col>
                    <Col span={span}>
                        <ProFormDigit
                            width="md"
                            name="loadedQTY"
                            label="LoadedQTY"
                            placeholder=""
                            fieldProps={{
                                max: focusDevannedRecord.loadedQTY,
                                min: 1
                            }}
                            initialValue={focusDevannedRecord.loadedQTY}
                        />
                    </Col>
                    <Col span={span}>
                        <ProFormDigit
                            width="md"
                            name="pallets"
                            label="Pallets"
                            placeholder=""
                            fieldProps={{
                                max: focusDevannedRecord.pallets,
                                min: 0
                            }}
                            initialValue={focusDevannedRecord.pallets}
                        />
                    </Col>
                    <Col span={span}>
                        <ProFormSelect
                            label='deliveryMethod' name='deliveryMethod' options={deliveryMethodOptions.map(it => ({ label: it, value: it }))} />
                    </Col>
                    <Col span={span}>
                        <ProFormDatePicker initialValue={focusDevannedRecord.deliveryDate} name="deliveryDate" label="DeliveryDate" />
                    </Col>
                    <Col span={span}>
                        <ProFormTimePicker fieldProps={{ format: 'HH:mm', minuteStep: 10, changeOnBlur: true, showNow: false }} name="deliveryTime" label="DeliveryTime" />
                    </Col>
                </Row>
            </ProForm.Group>
        </ModalForm>
    );
};