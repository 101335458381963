import { Button, Col, Drawer, Popover, Row, Space, Table, Tabs, Typography } from "antd"
import axios from "axios"
import { SetStateAction, useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import dayjs from 'dayjs';
import { dashboardTableDataState, summaryDrawerIsOpenState } from "./recoil.state";
import printJS from "print-js";
import { roleState } from "../stateManage";
import { set } from "@ant-design/plots/es/core/utils";
import { title } from "process";
import GetBolFiles from "./getbolfile";

type DataType = {
  PO: string,
  boxes: number,
  pallets: number,
  deliveryDate: string,
  deliveryTime: string,
  customer: string,
  ChinaNote: string
  USNote: string
}
const OPENHOURS = 'Open Hours'
const CustomerTIME = 'Customer\\Time'
export const SummaryDrawer = () => {
  const [role, setRole] = useRecoilState(roleState)
  const dashboardTableData = useRecoilValue(dashboardTableDataState)
  const [columns, setColunms] = useState([])
  const [tableData, setTableData] = useState([])
  const [dataByLocation, setDataByLocation] = useState([])
  const [location, setlocation] = useState('')
  const [duplicatesPOObj, setduplicatesPOObj] = useState([])
  const [summaryDrawerIsOpen, setSummaryDrawerIsOpen] = useRecoilState(summaryDrawerIsOpenState)


  useEffect(() => {
    if (dashboardTableData.length == 0) {
      return
    }
    //location tabs
    let dataByLocation = dashboardTableData.reduce((acc, record) => {
      const location = record.location;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(record);
      return acc;
    }, {})

    setDataByLocation(dataByLocation)

  }, [summaryDrawerIsOpen])


  const fillTableData = (label: string) => {

    var data = dataByLocation[label].reduce((acc, cur) => {
      let customer = cur.shipper + '@@' + cur.customer
      let pallets = cur.pallets || 0
      let PO = cur.PO
      let time = cur.deliveryTime || OPENHOURS
      let boxes = cur.loadedQTY || 0
      if (acc[customer]) {
        if (!acc[customer][time]) {
          acc[customer][time] = {
            boxes: boxes,
            pallets: pallets,
            pos: { [PO]: 1 }
          }
        } else {
          acc[customer][time].boxes = acc[customer][time].boxes + boxes
          acc[customer][time].pallets = acc[customer][time].pallets + pallets
          acc[customer][time].pos[PO] = 1
        }

      } else {
        acc[customer] = {
          [time]: {
            boxes: boxes,
            pallets: pallets,
            pos: { [PO]: 1 }
          }

        }
      }
      return acc
    }, {})
    var tableD = []
    var timeColumn = {}
    for (let customer in data) {
      let chip = {}
      let [shiper, cust] = customer.split('@@')
      chip[CustomerTIME] = cust
      chip['Shipper'] = shiper
      for (let time in data[customer]) {
        let poCount = Object.keys(data[customer][time].pos).length
        let pallets = data[customer][time].pallets
        let boxes = data[customer][time].boxes
        chip[time] = `${poCount}PO/${boxes}CTNS/${pallets}P`
        timeColumn[time] = 1
      }
      tableD.push(chip)
    }
    var column: String[] = Object.keys(timeColumn)
    column = column.filter(it => it != OPENHOURS)
    column = column.sort((a, b) => {
      return (+new Date('1970-01-01 ' + a)) - (+new Date('1970-01-01 ' + b));
    });
    if (role == 'admin') {
      column.unshift(`Shipper`)
    }
    column.unshift(CustomerTIME)
    column.push(OPENHOURS)
    column = column.map((title, i) => {
      if (title == 'Shipper' || title == CustomerTIME) {
        return {
          title: title,
          dataIndex: title,
          key: i,
          align: 'center',
          render: (text, record, index) => {
            return <span>{text}</span>
          }
        }
      }

      return {
        title: title,
        dataIndex: title,
        key: i,
        align: 'center',
        render: (text, record, index) => {
          if (!text && text !== 0) {
            return '-'
          }
          let locationData = dataByLocation[label]
          return <><span>{text}</span>{role == 'admin' && <GetBolFiles label={label} data={locationData} customer={record[CustomerTIME]} shipper={record['Shipper']} time={title} />} </>

        }
      }
    }) as any

    return [column, tableD]
  }

  const summaryClose = () => {
    setSummaryDrawerIsOpen(false)
  }
  const printClick = (timeColumn, tableDataSource) => {
    const jsonString = JSON.stringify(tableDataSource);
    var data = JSON.parse(jsonString);
    data.forEach(it => {
      //if it does have value in timeColumn, set to '-'
      timeColumn.forEach(time => {
        if (!it[time.dataIndex]) {
          it[time.dataIndex] = '-'
        }
      })
    })

    const prop = timeColumn.map((it, i) => {
      return { field: it.dataIndex, displayName: it.title }
    })

    const gridStyle = 'border: 1px solid lightgray; margin-bottom: -1px;'
      + 'text-align:center;'
      + 'font-size:18px;'

    printJS({
      printable: data, type: 'json',
      header: `${dashboardTableData[0].deliveryDate}`,
      properties: prop,
      gridStyle
    })
  }

  return <>{summaryDrawerIsOpen &&
    <Drawer width={1700} title={`Summary(${dashboardTableData[0].deliveryDate})`} placement="right" onClose={summaryClose} open={summaryDrawerIsOpen}>
      {Object.keys(dataByLocation).sort().map((location, i) => {
        let [timeColumn, tableDataSource] = fillTableData(location)
        // total boxes and pallets
        let totalBoxes = tableDataSource.reduce((acc, cur) => {
          let boxes = 0
          for (let time in cur) {
            if (time != OPENHOURS && time != 'Shipper' && time != CustomerTIME) {
              let [PO, CTNS, P] = cur[time].split('/')
              boxes += parseInt(CTNS)
            }
          }
          return acc + boxes
        }, 0)
        // total pallets
        let totalPallets = tableDataSource.reduce((acc, cur) => {
          let pallets = 0
          for (let time in cur) {
            if (time != OPENHOURS && time != 'Shipper' && time != CustomerTIME) {
              let [PO, CTNS, P] = cur[time].split('/')
              pallets += parseInt(P)
            }
          }
          return acc + pallets
        }, 0)

        //多批次OP合 
        const dulicateOP = () => {
          const groupedData = {};

          // 遍历数据并进行分组
          dataByLocation[location].forEach(item => {
            const key = `${item.shipper}@${item.customer}@${item.PO}`;
            if (!groupedData[key]) {
              groupedData[key] = [];
            }
            groupedData[key].push(item);
          });
          const duplicates = Object.entries(groupedData).filter(([key, value]) => (value as any[]).length > 1);
          return duplicates
        }

        const duplicatesPOObj = dulicateOP()
       
        return <>
          {
            role == 'admin' &&
            <Tabs defaultActiveKey="1" items={[
              {
                key: '1',
                label: location,
                children: null,
              }
            ]} />
          }
          <Row style={{ margin: '10px 10px' }}>
            <Col offset={22}><Button size="middle" type="primary" onClick={() => { printClick(timeColumn, tableDataSource) }}>Print</Button></Col>
          </Row>
          <Table size={'small'}
            id={'dashboardSummaryDrawerTable'}
            pagination={false}
            columns={timeColumn}
            dataSource={tableDataSource}
            footer={() => {
              return <>
                <span>{dashboardTableData.length > 0 ? 'TotalBoxes: ' + totalBoxes : ''}</span>
                <span style={{ marginLeft: '20px' }}>{dashboardTableData.length > 0 ? 'TotalPallets: ' + totalPallets : ''}</span>
              </>
            }}
          ></Table>

          <Row style={{ marginTop: '20px' }}>
            <Row style={{ fontWeight: 800, paddingRight: '20px' }}>Notes:</Row>
            <Row>
              {
                duplicatesPOObj.map((it: any) => {
                  const PO_Box = it[1].map((item: any) => {
                    return `${item.loadedQTY}/${item.totalQTY}`
                  })
                  return <Col span={24}>
                    {`PO: ${it[0].split('@')[2]} has ${it[1].length} batches, POBox: ${PO_Box.join(', ')}`}
                  </Col>
                })
              }
            </Row>

          </Row>

          <Row style={{ height: '50px' }}></Row>
        </>
      })}



    </Drawer>}
  </>
}