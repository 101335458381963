import { ProCard, StatisticCard } from '@ant-design/pro-card';
import RcResizeObserver from 'rc-resize-observer';
import { useState } from 'react';
import { Column } from '@ant-design/plots';
import { ScheduleColumn } from './column';
import { ShiperPie } from './pie';

const { Statistic } = StatisticCard;

export const DashboardPage = () => {
  const [responsive, setResponsive] = useState(false);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <ProCard
        size='small'
        title="Data Overview"
        extra="2024"
        split={responsive ? 'horizontal' : 'vertical'}
        headerBordered
        bordered
      >
        <ProCard split="horizontal">
          <ProCard split="horizontal">
            <ProCard split="vertical">
              <StatisticCard
                statistic={{
                  title: 'Pending Boxes',
                  value: 234,
                //   description: (
                //     <Statistic
                //       title="较本月平均流量"
                //       value="8.04%"
                //       trend="down"
                //     />
                //   ),
                }}
              />
              <StatisticCard
                statistic={{
                  title: 'In-Housed Boxes',
                  value: 234,
                //   description: (
                //     <Statistic title="月同比" value="8.04%" trend="up" />
                //   ),
                }}
              />
            </ProCard>
            <ProCard split="vertical">
              <StatisticCard
                statistic={{
                  title: 'Delivered Boxes',
                  value: '56',
                //   suffix: '个',
                }}
              />
              <StatisticCard
                statistic={{
                  title: 'In-Housed Pallets',
                  value: '134',
                //   suffix: '个',
                }}
              />
            </ProCard>
          </ProCard>
          <StatisticCard
            title="Schedule"
            chart={
                <ScheduleColumn />
            }
          />
        </ProCard>
        <StatisticCard
          title="Shiper Pallets"
          chart={
            <ShiperPie/>
          }
        />
      </ProCard>
    </RcResizeObserver>
  );
};