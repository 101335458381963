import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './pages/app';
import { RecoilRoot } from 'recoil';
import { ConfigProvider, ThemeConfig } from 'antd'
import en_GB from 'antd/locale/en_GB';

const theme: ThemeConfig = {
  token: {
    fontSize: 16,
    colorPrimary: '#52c41a',
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider theme={theme} locale={en_GB}>
    <React.StrictMode>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </React.StrictMode>
  </ConfigProvider>
);

// reportWebVitals(console.log);