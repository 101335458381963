import {
    ModalForm
} from '@ant-design/pro-form';
import { Form } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import { useEffect } from 'react';
import {  dashboardSearchOptionsIsOpenState } from './recoil.state';

export const SearchOptions = () => {
    const [dashboardSearchOptionsIsOpen, setDashboardSearchOptionsIsOpen] = useRecoilState(dashboardSearchOptionsIsOpenState)
    const [form] = Form.useForm();
    const saveEdit=(values)=>{
        console.log(values)
       
    }
    useEffect(()=>{
        
    },[])
    return (
        <ModalForm
            open={dashboardSearchOptionsIsOpen}
            title={'Search Options Select'}
            form={form}
            autoFocusFirstInput
            onOpenChange={(p) => setDashboardSearchOptionsIsOpen(p)}
            submitTimeout={2000}
            onFinish={async (values) => {
                saveEdit(values)
                return true;
            }}
        >
           
        </ModalForm>
    );
};