
import { FileTextTwoTone } from '@ant-design/icons';
import axios from 'axios';
import { getBolfileUrl } from './api';



export default function GetBolFiles({label, data, customer, shipper, time }) {
    
    //get pubic dir BOLDRAFT.xlsx
    const bolClick = () => {
        
        var dataS = data.filter(it => it.customer == customer && it.shipper == shipper && it.deliveryTime == time)

        axios.post(getBolfileUrl, { data: dataS, shipper },{
            responseType: 'blob'
        }).then(res => {
            // 处理接收到的blob数据
            const url = window.URL.createObjectURL(new Blob([res.data],{
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${time}_${customer}_${shipper}_${label.split(' ')[0]}.xlsx`); // 设置下载文件的名称和扩展名
            document.body.appendChild(link);
            link.click();

            // 清理URL对象和DOM元素
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(e => {
            console.log(e)
        })



    }


    return (
        <>
            <span onClick={bolClick} style={{ paddingLeft: '10px', cursor: 'pointer' }}><FileTextTwoTone /></span>
        </>
    )
}