import {
    ModalForm,
} from '@ant-design/pro-form';
import {  Form} from 'antd';
import { useRecoilState } from 'recoil';
import {  fillPalletsModelIsOpenState, palletsOfDevannedDrawerState } from '../recoil.state';
import TextArea from 'antd/es/input/TextArea';

export const FillPalletsModel = () => {
    const [fillPalletsModelIsOpen, setFillPalletsModelIsOpen] = useRecoilState(fillPalletsModelIsOpenState)
    const [palletsOfDevannedDrawer, setPalletsOfDevannedDrawer] = useRecoilState(palletsOfDevannedDrawerState)

    const [form] = Form.useForm();
    
    return (
        <ModalForm
            open={fillPalletsModelIsOpen}
            title={`fillPallets`}
            form={form}
            autoFocusFirstInput
            onOpenChange={(t) => setFillPalletsModelIsOpen(t)}
            submitTimeout={2000}
            onFinish={async (values) => {
                if(!values.pallets){
                    setPalletsOfDevannedDrawer([])
                    return true
                }
                var pallets = values.pallets.split('\n')

                pallets = pallets.map(it=>{
                    let p = Number(it.trim()) 
                    if(Number.isNaN(p)){
                        return null
                    }else {
                        return  p
                    }
                })
                setPalletsOfDevannedDrawer(pallets)
                return true
            }}
        >
            <div style={{height:'20px'}}></div>
            <Form.Item  name="pallets">
                <TextArea placeholder='pallets split by Enter' rows={7} cols={35}  />
            </Form.Item>
        </ModalForm>
    );
};