import { Column } from "@ant-design/plots";
import { useEffect, useState } from "react";


export const ScheduleColumn = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const data = [
            { type: '8:00', boxes: 30 },
            { type: '9:30', boxes: 12 },
            { type: '10:30', boxes: 35 },
            { type: '12:20', boxes: 22 },
            { type: '14:40', boxes: 2 },
            { type: '16:00', boxes: 6 },
        ];
        setData(data)
    }, []);


    const config = {
        data,
        xField: 'type',
        yField: 'boxes',
        style: {
            fill: ({ type }) => {
                return '#2989FF';
            },
        },
        label: {
            text: (originData) => {
                const val = parseFloat(originData.value);
                if (val < 0.05) {
                    return (val * 100).toFixed(1) + '%';
                }
                return '';
            },
            offset: 10,
        },
        legend: false,
    };
    return <>
        <Column {...config} />
    </>;
};